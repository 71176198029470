import { defineStore } from "pinia";
import type { LabDay } from "~~/types/strapi/api/lab-day/content-types/lab-day/lab-day";
import type { Payload } from "~~/types/strapi/common/schemas-to-ts/Payload";

export const useLabDayStore = defineStore("labDay", () => {
  const labDay = ref<LabDay | undefined | null>();
  const pending = ref<boolean>(false);
  const fetchedLabDay = ref<boolean>(false);

  async function fetchLabDay(token?: string) {
    if (token === undefined) {
      return;
    }

    if (isNotNullOrUndefined(labDay.value)) {
      fetchedLabDay.value = true;
      return;
    }

    pending.value = true;
    const { data } = await useApiFetch<Payload<LabDay[]>>(`/api/lab-days/${token}`);
    fetchedLabDay.value = true;
    const labDays = data.value?.data;

    if (labDays?.length !== 1) {
      labDay.value = null;
      pending.value = false;
      return;
    }

    labDay.value = labDays[0];
    pending.value = false;
  }

  // this will also be true if lab day is not found
  const hasLabDayTokenAndIsLoaded = computed(() => pending.value === false && fetchedLabDay.value === true);
  const isValidLabDay = computed(() => hasLabDayTokenAndIsLoaded.value === true && isNotNullOrUndefined(labDay.value));

  function $reset(): void {
    labDay.value = undefined;
  }

  return { $reset, fetchLabDay, hasLabDayTokenAndIsLoaded, isValidLabDay, labDay, pending };
});
